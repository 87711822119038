import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Table, Row, Col, Form, Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import imageUrl from "../ImageUrl";
import fileString from "../assets/language/filepath.json";

import {
  viewprofile,
  similarprofiledetails,
  approve,
  holdpop,
  holdapproval,
  rejectapproval,
  blockapproval,
  activeplayer,
  pendingdocument,
  sendcredentials,
  delprofile,
  bplreject,
  rejectedreasons,
  reapplyprintdata,
} from "../services/superadmin";
// import { logindata } from "../../../services/loginservice";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { data } from "autoprefixer";
const ViewProfilePayment = () => {
  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");

  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [dor, setDOR] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [blood, setBlood] = useState("");
  const [aadhar, setAadhar] = useState("");

  const [commaddr, setCommAddr] = useState("");
  const [commaddr2, setCommaddr2] = useState("");
  const [commstate, setCommState] = useState("");
  const [commdistname, setCommDistname] = useState("");
  const [commpin, setCommPincode] = useState("");
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [state, setState] = useState("");
  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");

  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [uid, setUid] = useState("");
  const [status, setStatus] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formid, setIDFile] = useState("");
  const [bpl, setBpl] = useState("");
  const [bplstatus, setBplstatus] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [passApprove, setPassApprove] = useState("");
  const [similar, setSimilar] = useState([]);
  const [otherreason, setOtherreason] = useState("");

  const [orderid, setOrderid] = useState("");
  const [paymentdate, setPaymentdate] = useState("");
  const [statusp, setStatusp] = useState("");

  const getPassword = localStorage.getItem("password");
  const { id } = useParams();

  //selectedvalue
  const [selectedValue, setSelectedValue] = useState([]);

  function errorhandle(ev) {
    ev.target.src = require("../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  const navigate = useNavigate();
  const [rejcount, setRejectCnt] = useState(0);
  const [pendoccount, setPenDoc] = useState(0);
  const [reaview, setReasonView] = useState([]);

  const [perlist, setPerformance] = useState([]);
  const [reapply, setReapply] = useState("");
  const [reapplyrea, setReapplyReason] = useState([]);

  useEffect(() => {
    viewprofile(id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.details.first_name);
        setMiddle(response.data.details.middle_name);
        setLast(response.data.details.last_name);
        setDOB(
          moment(response.data.details.date_of_birth).format("DD-MM-YYYY")
        );
        if (response.data.details.created_at !== null) {
          setDOR(moment(response.data.details.created_at).format("DD-MM-YYYY"));
        }
        setGender(response.data.details.gender);
        setEmail(response.data.details.email_id);
        setMobile(response.data.details.mobile_no);
        setCommAddr(response.data.details.communication_address_one);
        setCommaddr2(response.data.details.communication_address_two);
        setCommDistname(response.data.details.communication_district);

        for (let i = 0; i < response.data.comm_state.length; i++) {
          if (
            response.data.details.communication_state ===
            response.data.comm_state[i].stateuid
          ) {
            setCommState(response.data.comm_state[i].statename);
          }
        }
        setCommPincode(response.data.details.communication_zipcode);
        setAddr(response.data.details.permenant_address_one);
        setAddr2(response.data.details.permenant_address_two);
        setDistname(response.data.details.permenant_district);
        setPincode(response.data.details.permenant_zipcode);
        setState(response.data.details.statename);
        setFather(response.data.details.father_name);
        setMother(response.data.details.mother_name);
        setBlood(response.data.details.blood_group);
        setAadhar(response.data.details.aadhaar_no);
        setStatus(response.data.details.status);
        setOrderid(response.data.details.order_id);
        if(response.data.details.paymentdatetime !== null){
          setPaymentdate(
            moment(response.data.details.paymentdatetime).format("DD-MM-YYYY")
          );
        }
        
        setStatusp(response.data.details.payment_status);
        setBplstatus(response.data.details.below_poverty_card);
        setPhotoFile(
          imageUrl + fileString.athletephoto + response.data.details.photograph
        );
        setIDFile(
          imageUrl + fileString.athleteidproof + response.data.details.dob_proof
        );
        setAddrFile(
          imageUrl +
            fileString.athleteaddress +
            response.data.details.address_proof
        );
        setBpl(
          imageUrl +
            fileString.athletebplcard +
            response.data.details.below_poverty_file
        );
        setUid(response.data.details.username);
        setRejectCnt(response.data.details.rejected_count);
        setPenDoc(response.data.details.improper_docs);
        setPerformance(response.data.results);
        if (response.data.reapply_idcard.length !== 0) {
          setReapplyReason(response.data.reapply_idcard);
        }
        similarprofiledetails(
          response.data.details.first_name,
          response.data.details.date_of_birth,
          id,
          response.data.details.father_name
        ).then((res) => {
          if (res.status === true) {
            setSimilar(res.data);
          }
        });
      }
    });

    rejectedreasons(id, 2).then((response) => {
      if (response.status === true) {
        setReasonView(response.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">User Profile</h2>
        </div>
        <div className="text-right">
          <Link className="btn btn-secondary" to={"/superadmin/paymentlist"}>Back</Link>
        </div>
      </div>{" "}
      <Row className="row row-sm">
        <Col lg={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6>SIMILAR PROFILES (based on DOB & Name)</h6>
              </div>

              <div
                responsive="true"
                className="table-responsive border border-bottom-0"
              >
                <Table className="table text-nowrap text-md-nowrap mg-b-0">
                  <thead>
                    <tr>
                      <th>AFI-UID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Gender</th>
                      <th>DOB</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>DOR</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {similar.map((list, index) => (
                      <tr key={index} data-index={index}>
                        <td>{list.username} </td>
                        <td>{list.first_name}</td>
                        <td>{list.last_name}</td>
                        <td>{list.gender}</td>
                        <td>
                          {moment(list.date_of_birth).format("DD-MM-YYYY")}
                        </td>
                        <td>{list.email_id}</td>
                        <td>{list.mobile_no}</td>

                        {list.registerdate !== null && (
                          <td>
                            {moment(list.registerdate).format("DD-MM-YYYY")}
                          </td>
                        )}
                        {list.registerdate === null && <td>-</td>}
                        {list.status === 0 && <td>Pending</td>}
                        {list.status === 1 && <td>Approved</td>}
                        {list.status === 2 && <td>Rejected</td>}
                        {list.status === 3 && <td>Blocked</td>}
                        {list.status === 4 && <td>Hold</td>}
                        <td>
                          <Link
                            className="btn-sm btn-info"
                            to={"/superadmin/viewprofile/" + list.user_id}
                            target="_blank"
                          >
                            <i className="fa fa-eye fa-lg"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="row row-sm">
        <Col lg={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6>PERFORMANCE</h6>
              </div>

              <div
                responsive="true"
                className="table-responsive border border-bottom-0"
              >
                <Table className="table text-nowrap text-md-nowrap mg-b-0">
                  <thead>
                    <tr>
                      <th>MEET</th>
                      <th>EVENT</th>
                      <th>CATEGORY</th>
                      <th>VENUE</th>
                      <th>RACE</th>
                      <th>DATE</th>
                      <th>RESULT</th>
                      <th>PERFORMANCE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {perlist.map((list, index) => (
                      <tr key={index} data-index={index}>
                        <td>{list.tour_name} </td>
                        <td>{list.event_name}</td>
                        <td>{list.age_category}</td>

                        <td>{list.tour_location}</td>
                        <td>{list.event_status}</td>
                        <td>
                          {moment(list.uploaded_date).format("DD-MM-YYYY")}
                        </td>
                        <td>{list.result}</td>
                        <td>{list.time_taken}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{first}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Middle Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{middle}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{last}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Blood Group</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{blood}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Aadhar Number</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{aadhar}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Father&apos;S Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{father}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mother&apos;S Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mother}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">AFI-UID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uid}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          {status === 0 && (
                            <span
                              className="text-inverse"
                              style={{ color: "#17a2b8", fontWeight: "600" }}
                            >
                              PENDING
                            </span>
                          )}
                          {status === 1 && (
                            <span
                              className="text-inverse"
                              style={{ color: "green", fontWeight: "600" }}
                            >
                              APPROVED
                            </span>
                          )}
                          {status === 2 && (
                            <span
                              className="text-inverse"
                              style={{ color: "red", fontWeight: "600" }}
                            >
                              REJECTED
                            </span>
                          )}
                          {status === 3 && (
                            <span
                              className="text-inverse"
                              style={{ color: "red", fontWeight: "600" }}
                            >
                              BLOCKED
                            </span>
                          )}
                          {status === 4 && (
                            <span
                              className="text-inverse"
                              style={{ color: "#17a2b8", fontWeight: "600" }}
                            >
                              HOLD
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOR</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dor}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">BPL Card</b>
                        </div>
                        <div className="col-md-8">
                          {bplstatus === "Y" && (
                            <span className="text-inverse">Yes</span>
                          )}
                          {bplstatus !== "Y" && (
                            <span className="text-inverse">No</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <h5>PERMANENT ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>COMMUNICATION ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{state}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commstate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{ppin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commpin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <hr></hr>
                <div className="row">
                  <div className="col-md-12">
                    <h5>PAYMENT DETAILS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Order ID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{orderid}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Payment Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{paymentdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          {statusp === 1 && (
                            <span className="text-inverse">Paid</span>
                          )}
                          {statusp !== 1 && (
                            <span className="text-inverse">Not Paid</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Photograph</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formphoto}
                          alt="photo"
                          onClick={() => handleshow(formphoto)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">ID Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formid}
                          alt="photo"
                          onClick={() => handleshow(formid)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Address Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formaddr}
                          alt="photo"
                          onClick={() => handleshow(formaddr)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  {bplstatus === "Y" && (
                    <div className="col-md-4 bpl-gap">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">BPL Card</b>
                        </div>
                        <div className="col-md-8">
                          <img
                            className="profile_img"
                            src={bpl}
                            alt="photo"
                            onClick={() => handleshow(bpl)}
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <hr />
                {status === 2 && rejcount !== 0 && (
                  <div>
                    <h5>REJECTED REASONS</h5>
                    <ul>
                      {reaview.map((data, i) => {
                        return <li key={i}>{data.reasons}</li>;
                      })}
                    </ul>
                  </div>
                )}
                {reapply === "athleteidcardview" && (
                  <div>
                    <h5>REAPPLY IDCARD</h5>
                    {reapplyrea.map((data, i) => {
                      return (
                        <div key={i}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Reason</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.reason}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Status</b>
                                  </div>
                                  {data.delivered_status === 1 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        Delivered
                                      </span>
                                    </div>
                                  )}
                                  {data.printed_status === 0 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        Pending
                                      </span>
                                    </div>
                                  )}
                                  {data.delivered_status === 0 &&
                                    data.printed_status === 1 && (
                                      <div className="col-md-8">
                                        <span className="text-inverse">
                                          Printed
                                        </span>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Payment ID</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.payment_tracking_id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Payment Status
                                    </b>
                                  </div>
                                  {data.payment_status === 1 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">Paid</span>
                                    </div>
                                  )}
                                  {data.payment_status !== 1 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        Unpaid
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Order ID</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.order_id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Order Date</b>
                                  </div>
                                  {data.order_date !== null && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {moment(data.order_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Order Status</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.order_status}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                  </div>
                )}

                <br />
                <hr></hr>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show}>
        <Modal.Body>
          <img src={file} alt="Image" onError={(e) => errorhandle(e)} />
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success"
            style={{ float: "right" }}
            href={file}
            download
            target="_blank"
          >
            Download
          </a>
          <a
            className="btn btn-next btn-secondary"
            style={{ float: "right" }}
            onClick={handleclose}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ViewProfilePayment;
