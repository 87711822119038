import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Table, Row, Col, Form, Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import imageUrl from "../../../ImageUrl";
import fileString from "../../../assets/language/filepath.json";

import {
  viewprofile,
  similarprofiledetails,
  approve,
  holdpop,
  holdapproval,
  rejectapproval,
  blockapproval,
  activeplayer,
  pendingdocument,
  sendcredentials,
  delprofile,
  bplreject,
  rejectedreasons,
  reapplyprintdata,
} from "../../../services/superadmin";
// import { logindata } from "../../../services/loginservice";
import "./viewprofile.css";
import "./popup.css";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { data } from "autoprefixer";
const ViewProfile = () => {
  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");

  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [dor, setDOR] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [blood, setBlood] = useState("");
  const [aadhar, setAadhar] = useState("");

  const [commaddr, setCommAddr] = useState("");
  const [commaddr2, setCommaddr2] = useState("");
  const [commstate, setCommState] = useState("");
  const [commdistname, setCommDistname] = useState("");
  const [commpin, setCommPincode] = useState("");
  const [addr, setAddr] = useState("");
  const [addr2, setAddr2] = useState("");
  const [state, setState] = useState("");
  const [pdistname, setDistname] = useState("");
  const [ppin, setPincode] = useState("");

  const [father, setFather] = useState("");
  const [mother, setMother] = useState("");
  const [uid, setUid] = useState("");
  const [status, setStatus] = useState("");
  const [formphoto, setPhotoFile] = useState("");
  const [formid, setIDFile] = useState("");
  const [bpl, setBpl] = useState("");
  const [bplstatus, setBplstatus] = useState("");
  const [formaddr, setAddrFile] = useState("");
  const [passApprove, setPassApprove] = useState("");
  const [Errmsg, setErrMsg] = useState("");
  const [similar, setSimilar] = useState([]);
  const [reasonlist, setReasonlist] = useState([]);
  const [otherreason, setOtherreason] = useState("");
  const [credential, setCredential] = useState("");

  const rarray = [];
  const [otherid, setOtherid] = useState(false);

  const [orderid, setOrderid] = useState("");
  const [paymentdate, setPaymentdate] = useState("");
  const [statusp, setStatusp] = useState("");

  const getPassword = localStorage.getItem("password");
  const { id } = useParams();
  const [appr, setShow1] = useState(false);
  const [hold, setShow2] = useState(false);
  const [rej, setShow3] = useState(false);
  const [block, setShow4] = useState(false);
  const [activate, setShow5] = useState(false);
  const [pdoc, setShow6] = useState(false);
  const [scredential, setShow7] = useState(false);
  const [deleteprofile, setShow8] = useState(false);
  const [bplrej, setShow9] = useState(false);
  //selectedvalue
  const [selectedValue, setSelectedValue] = useState([]);
  const [rejvalue, setRejValue] = useState([]);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  function handleshow(img) {
    setFile("");
    setFile(img);
    setShow(true);
  }

  function handleclose() {
    setShow(false);
  }

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setRejValue(e);
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
    for (let i = 0; i < e.length; i++) {
      if (e[i].value === 6) {
        setOtherid(true);
      } else {
        setOtherid(false);
      }
    }
  };

  const navigate = useNavigate();

  function setAShow() {
    setErrMsg("");
    setShow1(true);
  }

  function setAClose() {
    setErrMsg("");
    setShow1(false);
  }

  function setHShow() {
    setErrMsg("");
    setShow2(true);
  }

  function setHClose() {
    setErrMsg("");
    setShow2(false);
  }
  function setRShow() {
    setErrMsg("");
    setShow3(true);
  }

  function setRClose() {
    setErrMsg("");
    setShow3(false);
  }
  function setBShow() {
    setErrMsg("");
    setShow4(true);
  }

  function setBClose() {
    setErrMsg("");
    setShow4(false);
  }
  function setActiveShow() {
    setErrMsg("");
    setShow5(true);
  }

  function setActiveClose() {
    setErrMsg("");
    setShow5(false);
  }
  function setPdocShow() {
    setErrMsg("");
    setShow6(true);
  }

  function setPdocClose() {
    setErrMsg("");
    setShow6(false);
  }
  function setSendCredentialactive() {
    sendcredentials(id).then((response) => {
      if (response.status === true) {
        setCredential(response.message);
        setShow7(true);
      }
    });
  }

  function setSendCredentialclose() {
    setShow7(false);
  }
  function setDeleteactive() {
    setShow8(true);
  }
  function setDeleteclose() {
    setShow8(false);
  }
  function setBplShow() {
    setShow9(true);
  }

  function setBplClose() {
    setShow9(false);
  }
  function Approve(e) {
    e.preventDefault();
    setErrMsg("");
    if (passApprove !== "") {
      if (getPassword === passApprove) {
        const tempdata = new FormData();
        tempdata.append("user_id", id);
        approve(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/approvedathletes/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  function BplRejection(e) {
    e.preventDefault();
    setErrMsg("");
    if (passApprove !== "") {
      if (getPassword === passApprove) {
        bplreject(id).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/pendingathletes/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  function Hold(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        holdapproval(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/holdathletes/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  function Reject(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        rejectapproval(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/rejectedathletes/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  function Block(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        blockapproval(tempdata).then((response) => {
          if (response.status === true) {
            setBClose();
            navigate("/superadmin/blockedathletes/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  //delete profile
  function Delete(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        delprofile(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/deletedathlete/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  function Active(e) {
    e.preventDefault();
    setErrMsg("");

    if (passApprove !== "") {
      if (getPassword === passApprove) {
        let holdrvalue = "";
        if (selectedValue.length !== 0) {
          for (let i = 0; i < selectedValue.length; i++) {
            if (holdrvalue === "") {
              holdrvalue = selectedValue[i];
            } else {
              holdrvalue = holdrvalue + "," + selectedValue[i];
            }
          }
        }
        const tempdata = {
          user_id: id,
          reasonsid: holdrvalue + "",
          remarks: otherreason,
        };
        activeplayer(tempdata).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/approvedathletes/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  function Pendingdoc(e) {
    e.preventDefault();
    setErrMsg("");
    if (passApprove !== "") {
      if (getPassword === passApprove) {
        const tempdata = {
          user_id: id,
        };
        pendingdocument(tempdata.user_id).then((response) => {
          if (response.status === true) {
            navigate("/superadmin/pendingdoc/");
          }
        });
      } else {
        setErrMsg("Password Doesn't match!");
      }
    } else {
      setErrMsg("Please enter you password");
    }
  }
  const [rejcount, setRejectCnt] = useState(0);
  const [pendoccount, setPenDoc] = useState(0);
  const [reaview, setReasonView] = useState([]);

  const [perlist, setPerformance] = useState([]);
  const [reapply, setReapply] = useState("");
  const [reapplyrea, setReapplyReason] = useState([]);

  const [subrole, setSubRole] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("SubRoleid") === "1") {
      setSubRole(false);
    } else if (localStorage.getItem("SubRoleid") === "2") {
      setSubRole(false);
    } else {
      setSubRole(true);
    }
    const url = window.location.href;
    const last = url.split("/#/");
    const link = last[1].split("/");
    const eid = link[1];
    setReapply(eid);
    viewprofile(id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.details.first_name);
        setMiddle(response.data.details.middle_name);
        setLast(response.data.details.last_name);
        setDOB(
          moment(response.data.details.date_of_birth).format("DD-MM-YYYY")
        );
        if (response.data.details.created_at !== null) {
          setDOR(moment(response.data.details.created_at).format("DD-MM-YYYY"));
        }
        setGender(response.data.details.gender);
        setEmail(response.data.details.email_id);
        setMobile(response.data.details.mobile_no);
        setCommAddr(response.data.details.communication_address_one);
        setCommaddr2(response.data.details.communication_address_two);
        setCommDistname(response.data.details.communication_district);

        for (let i = 0; i < response.data.comm_state.length; i++) {
          if (
            response.data.details.communication_state ===
            response.data.comm_state[i].stateuid
          ) {
            setCommState(response.data.comm_state[i].statename);
          }
        }
        setCommPincode(response.data.details.communication_zipcode);
        setAddr(response.data.details.permenant_address_one);
        setAddr2(response.data.details.permenant_address_two);
        setDistname(response.data.details.permenant_district);
        setPincode(response.data.details.permenant_zipcode);
        setState(response.data.details.statename);
        setFather(response.data.details.father_name);
        setMother(response.data.details.mother_name);
        setBlood(response.data.details.blood_group);
        setAadhar(response.data.details.aadhaar_no);
        setStatus(response.data.details.status);
        setOrderid(response.data.details.order_id);
        
        if(response.data.details.paymentdatetime !== null){

         var mdate = response.data.details.paymentdatetime.split(" ")
          setPaymentdate(
            moment(mdate[0]).format("DD-MM-YYYY")
          );
        }
        setStatusp(response.data.details.payment_status);
        setBplstatus(response.data.details.below_poverty_card);
        setPhotoFile(
          imageUrl + fileString.athletephoto + response.data.details.photograph
        );
        setIDFile(
          imageUrl + fileString.athleteidproof + response.data.details.dob_proof
        );
        setAddrFile(
          imageUrl +
            fileString.athleteaddress +
            response.data.details.address_proof
        );
        setBpl(
          imageUrl +
            fileString.athletebplcard +
            response.data.details.below_poverty_file
        );
        setUid(response.data.details.username);
        setRejectCnt(response.data.details.rejected_count);
        setPenDoc(response.data.details.improper_docs);
        setPerformance(response.data.results);
        if (response.data.reapply_idcard.length !== 0) {
          setReapplyReason(response.data.reapply_idcard);
        }
        similarprofiledetails(
          response.data.details.first_name,
          response.data.details.date_of_birth,
          id,
          response.data.details.father_name
        ).then((res) => {
          if (res.status === true) {
            setSimilar(res.data);
          }
        });
      }
    });

    holdpop().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            rarray.push({
              value: response.data[i].id,
              label: response.data[i].reason_name,
            });
          }
        }
        setReasonlist(rarray);
      }
    });

    rejectedreasons(id, 2).then((response) => {
      if (response.status === true) {
        setReasonView(response.data);
      }
    });
  }, []);

  function printidcard() {
    reapplyprintdata(id).then((response) => {
      if (response.status === true) {
        navigate("/superadmin/printedidcard/");
      }
    });
  }
  // sendcredentials(id).then((response) => {
  //   if (response.status === true) {
  //     setCredential(response.message);
  //   }
  // });

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">User Profile</h2>
        </div>
      </div>{" "}
      <Row className="row row-sm">
        <Col lg={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6>SIMILAR PROFILES (based on DOB & Name)</h6>
              </div>

              <div
                responsive="true"
                className="table-responsive border border-bottom-0"
              >
                <Table className="table text-nowrap text-md-nowrap mg-b-0">
                  <thead>
                    <tr>
                      <th>AFI-UID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Gender</th>
                      <th>DOB</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>DOR</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {similar.map((list, index) => (
                      <tr key={index} data-index={index}>
                        <td>{list.username} </td>
                        <td>{list.first_name}</td>
                        <td>{list.last_name}</td>
                        <td>{list.gender}</td>
                        <td>
                          {moment(list.date_of_birth).format("DD-MM-YYYY")}
                        </td>
                        <td>{list.email_id}</td>
                        <td>{list.mobile_no}</td>

                        {list.registerdate !== null && (
                          <td>
                            {moment(list.registerdate).format("DD-MM-YYYY")}
                          </td>
                        )}
                        {list.registerdate === null && <td>-</td>}
                        {list.status === 0 && <td>Pending</td>}
                        {list.status === 1 && <td>Approved</td>}
                        {list.status === 2 && <td>Rejected</td>}
                        {list.status === 3 && <td>Blocked</td>}
                        {list.status === 4 && <td>Hold</td>}
                        <td>
                          <Link
                            className="btn-sm btn-info"
                            to={"/superadmin/viewprofile/" + list.user_id}
                            target="_blank"
                          >
                            <i className="fa fa-eye fa-lg"></i>
                          </Link>
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="row row-sm">
        <Col lg={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6>PERFORMANCE</h6>
              </div>

              <div
                responsive="true"
                className="table-responsive border border-bottom-0"
              >
                <Table className="table text-nowrap text-md-nowrap mg-b-0">
                  <thead>
                    <tr>
                      <th>MEET</th>
                      <th>EVENT</th>
                      <th>CATEGORY</th>
                      <th>VENUE</th>
                      <th>RACE</th>
                      <th>DATE</th>
                      <th>RESULT</th>
                      <th>PERFORMANCE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {perlist.map((list, index) => (
                      <tr key={index} data-index={index}>
                        <td>{list.tour_name} </td>
                        <td>{list.event_name}</td>
                        <td>{list.age_category}</td>

                        <td>{list.tour_location}</td>
                        <td>{list.event_status}</td>
                        <td>
                          {moment(list.uploaded_date).format("DD-MM-YYYY")}
                        </td>
                        <td>{list.result}</td>
                        <td>{list.time_taken}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{first}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Middle Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{middle}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{last}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOB</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Blood Group</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{blood}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Aadhar Number</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{aadhar}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Father&apos;S Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{father}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Mother&apos;S Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{mother}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">AFI-UID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uid}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          {status === 0 && (
                            <span
                              className="text-inverse"
                              style={{ color: "#17a2b8", fontWeight: "600" }}
                            >
                              PENDING
                            </span>
                          )}
                          {status === 1 && (
                            <span
                              className="text-inverse"
                              style={{ color: "green", fontWeight: "600" }}
                            >
                              APPROVED
                            </span>
                          )}
                          {status === 2 && (
                            <span
                              className="text-inverse"
                              style={{ color: "red", fontWeight: "600" }}
                            >
                              REJECTED
                            </span>
                          )}
                          {status === 3 && (
                            <span
                              className="text-inverse"
                              style={{ color: "red", fontWeight: "600" }}
                            >
                              BLOCKED
                            </span>
                          )}
                          {status === 4 && (
                            <span
                              className="text-inverse"
                              style={{ color: "#17a2b8", fontWeight: "600" }}
                            >
                              HOLD
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">DOR</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dor}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">BPL Card</b>
                        </div>
                        <div className="col-md-8">
                          {bplstatus === "Y" && (
                            <span className="text-inverse">Yes</span>
                          )}
                          {bplstatus !== "Y" && (
                            <span className="text-inverse">No</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <h5>PERMANENT ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>COMMUNICATION ADDRESS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{addr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commaddr2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">District</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commdistname}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{state}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commstate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{ppin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{commpin}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <hr></hr>
                <div className="row">
                  <div className="col-md-12">
                    <h5>PAYMENT DETAILS</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Order ID</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{orderid}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Payment Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{paymentdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          {statusp === 1 && (
                            <span className="text-inverse">Paid</span>
                          )}
                          {statusp !== 1 && (
                            <span className="text-inverse">Not Paid</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Photograph</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formphoto}
                          alt="photo"
                          onClick={() => handleshow(formphoto)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">ID Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formid}
                          alt="photo"
                          onClick={() => handleshow(formid)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Address Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={formaddr}
                          alt="photo"
                          onClick={() => handleshow(formaddr)}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>
                  </div>
                  {bplstatus === "Y" && (
                    <div className="col-md-4 bpl-gap">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">BPL Card</b>
                        </div>
                        <div className="col-md-8">
                          <img
                            className="profile_img"
                            src={bpl}
                            alt="photo"
                            onClick={() => handleshow(bpl)}
                            onError={(e) => errorhandle(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <hr />
                {status === 2 && rejcount !== 0 && (
                  <div>
                    <h5>REJECTED REASONS</h5>
                    <ul>
                      {reaview.map((data, i) => {
                        return <li key={i}>{data.reasons}</li>;
                      })}
                    </ul>
                  </div>
                )}
                {reapply === "athleteidcardview" && (
                  <div>
                    <h5>REAPPLY IDCARD</h5>
                    {reapplyrea.map((data, i) => {
                      return (
                        <div key={i}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Reason</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.reason}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Status</b>
                                  </div>
                                  {data.delivered_status === 1 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        Delivered
                                      </span>
                                    </div>
                                  )}
                                  {data.printed_status === 0 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        Pending
                                      </span>
                                    </div>
                                  )}
                                  {data.delivered_status === 0 &&
                                    data.printed_status === 1 && (
                                      <div className="col-md-8">
                                        <span className="text-inverse">
                                          Printed
                                        </span>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Payment ID</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.payment_tracking_id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">
                                      Payment Status
                                    </b>
                                  </div>
                                  {data.payment_status === 1 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">Paid</span>
                                    </div>
                                  )}
                                  {data.payment_status !== 1 && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        Unpaid
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Order ID</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.order_id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Order Date</b>
                                  </div>
                                  {data.order_date !== null && (
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {moment(data.order_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="profile-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <b className="text-inverse">Order Status</b>
                                  </div>
                                  <div className="col-md-8">
                                    <span className="text-inverse">
                                      {data.order_status}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })}

                    <div className="grp-btns text-center">
                      <button
                        className="btn-f btn-secondary mt-2"
                        onClick={() => navigate(-1)}
                      >
                        back
                      </button>

                      <button
                        className="btn-f btn-success mt-2"
                        onClick={printidcard}
                      >
                        Printing
                      </button>
                    </div>
                  </div>
                )}
                {reapply !== "athleteidcardview" && (
                  <div>
                    {/* Pending */}
                    {status === 0 && (
                      <div className="grp-btns text-center">
                        <button
                          className="btn-f btn-secondary mt-2"
                          onClick={() => navigate(-1)}
                        >
                          back
                        </button>
                        <button
                          className="btn-f btn-success mt-2"
                          onClick={setAShow}
                        >
                          approve profile
                        </button>
                        <button
                          className="btn-f btn-info mt-2"
                          onClick={setHShow}
                        >
                          hold profile
                        </button>
                        <button
                          className="btn-f btn-danger mt-2"
                          onClick={setRShow}
                        >
                          reject profile
                        </button>
                        {bplstatus === "Y" && (
                          <button
                            className="btn-f btn-light mt-2"
                            onClick={setBplShow}
                          >
                            Invalid BPL
                          </button>
                        )}
                        <button
                          className="btn-f btn-print mt-2"
                          onClick={() => window.print()}
                        >
                          print
                        </button>
                      </div>
                    )}
                    {/* Approved */}
                    {status === 1 && (
                      <div className="grp-btns text-center">
                        <button
                          className="btn-f btn-secondary mt-2"
                          onClick={() => navigate(-1)}
                          // onClick={() =>
                          //   navigate("/superadmin/approvedathletes")
                          // }
                        >
                          back
                        </button>
                        <button
                          className="btn-f btn-danger mt-2"
                          onClick={setBShow}
                        >
                          Block profile
                        </button>
                        {!subrole && (
                          <button
                            className="btn-f btn-success mt-2"
                            onClick={() =>
                              navigate("/superadmin/editathlete/" + id)
                            }
                          >
                            Edit
                          </button>
                        )}

                        {pendoccount === 0 && (
                          <button
                            className="btn-f btn-light mt-2"
                            onClick={setPdocShow}
                          >
                            Pending Document
                          </button>
                        )}

                        <button
                          className="btn-f btn-info mt-2"
                          onClick={setSendCredentialactive}
                        >
                          send credentials
                        </button>
                        <button
                          className="btn-f btn-print mt-2"
                          onClick={() => window.print()}
                        >
                          print
                        </button>
                      </div>
                    )}
                    {/* //Reject Athlets */}
                    {status === 2 && rejcount === 0 && (
                      <div className="grp-btns text-center">
                        <button
                          className="btn-f btn-secondary mt-2"
                          onClick={() => navigate(-1)}
                          // onClick={() =>
                          //   navigate("/superadmin/rejectedathletes")
                          // }
                        >
                          back
                        </button>

                        {!subrole && (
                          <button
                            className="btn-f btn-success mt-2"
                            onClick={() =>
                              navigate("/superadmin/editathlete/" + id)
                            }
                          >
                            Edit
                          </button>
                        )}
                        <button
                          className="btn-f btn-success mt-2"
                          onClick={setAShow}
                        >
                          Approve Profile
                        </button>
                        <button
                          className="btn-f btn-danger mt-2"
                          onClick={setDeleteactive}
                        >
                          Delete profile
                        </button>
                        <button
                          className="btn-f btn-print mt-2"
                          onClick={() => window.print()}
                        >
                          print
                        </button>
                      </div>
                    )}
                    {/* Hold */}
                    {status === 4 && (
                      <div className="grp-btns text-center">
                        <button
                          className="btn-f btn-secondary mt-2"
                          onClick={() => navigate(-1)}
                          // onClick={() => navigate("/superadmin/holdathletes")}
                        >
                          back
                        </button>
                        <button
                          className="btn-f btn-success mt-2"
                          onClick={setAShow}
                        >
                          Approve Profile
                        </button>
                        <button
                          className="btn-f btn-danger mt-2"
                          onClick={setDeleteactive}
                        >
                          Delete profile
                        </button>
                        <button
                          className="btn-f btn-print mt-2"
                          onClick={() => window.print()}
                        >
                          print
                        </button>
                      </div>
                    )}
                    {/* Blocked */}
                    {status === 3 && (
                      <div className="grp-btns text-center">
                        <button
                          className="btn-f btn-secondary mt-2"
                          onClick={() => navigate(-1)}
                        >
                          back
                        </button>
                        <button
                          className="btn-f btn-success mt-2"
                          onClick={setActiveShow}
                        >
                          active player
                        </button>
                        <button
                          className="btn-f btn-print mt-2"
                          onClick={() => window.print()}
                        >
                          print
                        </button>
                      </div>
                    )}

                    {/* Document updated - Rejected */}
                    {status === 2 && rejcount !== 0 && (
                      <div className="grp-btns text-center">
                        <button
                          className="btn-f btn-secondary mt-2"
                          onClick={() => navigate(-1)}
                        >
                          back
                        </button>
                        <button
                          className="btn-f btn-success mt-2"
                          onClick={setAShow}
                        >
                          approve profile
                        </button>
                        <button
                          className="btn-f btn-danger mt-2"
                          onClick={setRShow}
                        >
                          reject profile
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <br />
                <hr></hr>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show}>
        <Modal.Body>
          <img src={file} alt="Image" onError={(e) => errorhandle(e)} />
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-next btn-success"
            style={{ float: "right" }}
            href={file}
            download
            target="_blank"
          >
            Download
          </a>
          <a
            className="btn btn-next btn-secondary"
            style={{ float: "right" }}
            onClick={handleclose}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
      <Modal show={appr} size="large">
        <Modal.Header>
          <h6 className="rep-head">APPROVE PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setAClose}
            aria-hidden="true"
          ></i>
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Approve the membership application of {first} and allocate the
            Registration Number ?
          </h6>
          <div>
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-success" onClick={Approve}>
                Approve
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setAClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={hold} size="large">
        <Modal.Header>
          <h6 className="rep-head">HOLD PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setHClose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">Hold the application of {first}?</h6>
          <div>
            <div>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={reasonlist.filter((obj) =>
                  selectedValue.includes(obj.value)
                )}
                options={reasonlist} // set list of the data
                onChange={handleChange} // assign onChange function
                isMulti
                isClearable
              />
              {/* <Select
                className="dropdown"
                placeholder="Select Option"
                value={rejvalue}
                options={reasonlist}
                onChange={handleChange}
                isMulti
                isClearable
                hidden
              /> */}
            </div>
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-info" onClick={Hold}>
                Hold Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setHClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={rej} size="large">
        <Modal.Header>
          <h6 className="rep-head">REJECT PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setRClose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Reject the membership application of {first} ?
          </h6>
          <div>
            <div>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={reasonlist.filter((obj) =>
                  selectedValue.includes(obj.value)
                )} // set selected values
                options={reasonlist} // set list of the data
                onChange={handleChange} // assign onChange function
                isMulti
                isClearable
              />
            </div>
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-danger" onClick={Reject}>
                Reject Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setRClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={block} size="large">
        <Modal.Header>
          <h6 className="rep-head">BLOCK PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setBClose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Block the membership application of {first} ?
          </h6>
          <div>
            <div>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={reasonlist.filter((obj) =>
                  selectedValue.includes(obj.value)
                )} // set selected values
                options={reasonlist} // set list of the data
                onChange={handleChange} // assign onChange function
                isMulti
                isClearable
              />
            </div>
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-danger" onClick={Block}>
                Block Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setBClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={activate} size="large">
        <Modal.Header>
          <h6 className="rep-head">ACTIVE PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setActiveClose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Active the membership application of {first} ?
          </h6>
          <div>
            <div>
              <Select
                className="dropdown"
                placeholder="Select Option"
                value={reasonlist.filter((obj) =>
                  selectedValue.includes(obj.value)
                )} // set selected values
                options={reasonlist} // set list of the data
                onChange={handleChange} // assign onChange function
                isMulti
                isClearable
              />
            </div>
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-info" onClick={Active}>
                Active Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setActiveClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={pdoc} size="large">
        <Modal.Header>
          <h6 className="rep-head">PENDING DOCUMENT PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setPdocClose}
            aria-hidden="true"
          ></i>
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">Pending document profile of {first} ?</h6>
          <div>
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-light" onClick={Pendingdoc}>
                Pending Document
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setPdocClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={scredential} size="large">
        <Modal.Header>
          <h6 className="rep-head">ATHLETE CREDENTIAL</h6>

          <i
            className="fa fa-window-close fa-lg"
            onClick={setSendCredentialclose}
            aria-hidden="true"
          ></i>
        </Modal.Header>
        <Modal.Body className="mod-body">
          <div>
            <div className="rep__container">
              <p>{credential}</p>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setSendCredentialclose} className="btn-f btn-danger">
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* BPL Reject */}
      <Modal show={bplrej} size="large">
        <Modal.Header>
          <h6 className="rep-head">INVALID BPL PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setBplClose}
            aria-hidden="true"
          ></i>
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">
            Reject {first} profile because of invalid BPL?
          </h6>
          <div>
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-danger" onClick={BplRejection}>
                BPL Reject
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setBplClose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteprofile} size="large">
        <Modal.Header>
          <h6 className="rep-head">DELETE PROFILE</h6>
          <i
            className="fa fa-window-close fa-lg"
            onClick={setDeleteclose}
            aria-hidden="true"
          ></i>{" "}
        </Modal.Header>
        <Modal.Body className="mod-body">
          <h6 className="popup-modal">Delete the profile of {first} ?</h6>
          <div>
            <Select
              className="dropdown"
              placeholder="Select Option"
              value={reasonlist.filter((obj) =>
                selectedValue.includes(obj.value)
              )} // set selected values
              options={reasonlist} // set list of the data
              onChange={handleChange} // assign onChange function
              isMulti
              isClearable
            />
            {otherid && (
              <div className="col-lg textarea-reason">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter the reason"
                  onChange={(e) => {
                    setOtherreason(e.target.value);
                  }}
                ></Form.Control>
              </div>
            )}
            <div className="rep__container">
              <p>Your password to proceed:</p>
              <div className="col-lg">
                <div className="col-lg">
                  <Form.Control
                    placeholder="Password.."
                    type="password"
                    value={passApprove}
                    onChange={(e) => {
                      setPassApprove(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {Errmsg !== "" && (
              <span className="errortxt text-danger">{Errmsg}</span>
            )}

            <div className="approve-cen">
              <button className="btn-f btn-danger" onClick={Delete}>
                Delete Profile
              </button>
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={setDeleteclose} className="btn-f btn-danger">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ViewProfile;
