import React from "react";
import Profile from "../components/dashboard/athlete/profile/profile";
import Accountdetail from "../components/dashboard/athlete/accountdetail/accountdetail";
import Accountinfo from "../components/dashboard/athlete/accountdetail/accountinfo";
import Accountsetting from "../components/dashboard/accountsetting";
import Applynoc from "../components/dashboard/athlete/noc/applynoc";
import Dashboard from "../components/dashboard/dashboard";
import Statedashboard from "../components/dashboard/state/dashboard";
import Athleteidcard from "../components/dashboard/athlete/idcard";
import StateProfile from "../components/dashboard/state/stateprofile";
import OfficialProfile from "../components/dashboard/official/profile";
import DistrictProfile from "../components/dashboard/district/districtprofile";
import ClubProfile from "../components/dashboard/club/clubprofile";
import Pendingathlete from "../components/superadmin/athletes/pendingathlete";
import Approvedathlete from "../components/superadmin/athletes/approvedathletes";
import Rejectedathlete from "../components/superadmin/athletes/rejectedathlets";
import Blockedathlete from "../components/superadmin/athletes/blockedathletes";
import ViewProfile from "../components/superadmin/athletes/viewprofile";
import Holdathlete from "../components/superadmin/athletes/holdathletes";
import Allathlete from "../components/superadmin/athletes/allathletes";
import Pendingdoc from "../components/superadmin/athletes/pendingdoc";
import Deletedathlete from "../components/superadmin/athletes/deletedathlete";
import DocUpdate from "../components/superadmin/athletes/docupdate";
import SimilarProfile from "../components/superadmin/athletes/similarprofile";
import Addathlete from "../components/superadmin/athletes/addathlete";
import EditAthlete from "../components/superadmin/athletes/editathlete";
import Addmeet from "../components/superadmin/meet/addmeet";
import Addmeetmember from "../components/superadmin/meet/addmeetmember";
import Listmeet from "../components/superadmin/meet/listmeet";
import Districtathlete from "../components/dashboard/district/addathlete";
import Districtentry from "../components/dashboard/district/entrylist";
import Districtsummary from "../components/dashboard/district/viewsummary";
import AthleteDashboard from "../components/dashboard/athlete/dashboard";
import Confirmation from "../components/dashboard/district/confirmation";
import Payment from "../components/dashboard/district/payment";
import Meetaddathlete from "../components/dashboard/athlete/meetadd";
import DistrictDashboard from "../components/dashboard/district/dashboard";
import AddAthletes from "../components/dashboard/state/addathletes";
import Upcoming from "../components/dashboard/state/upcoming";
import AddOfficials from "../components/dashboard/state/addofficials";
import AddTraveldetails from "../components/dashboard/state/addtravel";
import AddCommittee from "../components/dashboard/state/selection";
import Summary from "../components/dashboard/state/summary";
import SendEntries from "../components/dashboard/state/sendentries";
import AthleteEntryList from "../components/dashboard/state/athleteentrylits";
import OfficialEntryList from "../components/dashboard/state/officialentrylist";
import TravelEntryList from "../components/dashboard/state/travelentrylist";
import AddDepaturedetails from "../components/dashboard/state/addtraveldeparture";
import Meetviewathlete from "../components/dashboard/athlete/meetview";
import StateConfirmation from "../components/dashboard/state/confirmation";
import Agecategorylist from "../components/superadmin/masters/agecategorylist";
import Roles from "../components/superadmin/masters/roles";
import Configuration from "../components/superadmin/masters/config";
import Discipline from "../components/superadmin/masters/discipline";
import States from "../components/superadmin/masters/states";
import Districts from "../components/superadmin/masters/district";
import Meetcategory from "../components/superadmin/masters/meetcategory";
import Entrycategory from "../components/superadmin/masters/entrycategory";
import Menumaster from "../components/superadmin/masters/menumaster";
import AthleteConfirmation from "../components/dashboard/athlete/confirmation";
import NOCathletelist from "../components/superadmin/noc/athletelist";
import NOCapprovedlist from "../components/superadmin/noc/approvedlist";
import NOCrejectedlist from "../components/superadmin/noc/rejectedlist";
import NOCloglist from "../components/superadmin/noc/loglist";
import NOCSuccess from "../components/dashboard/athlete/noc/nocsuccess";
import NOCFailure from "../components/dashboard/athlete/noc/nocfailure";
import ViewNOC from "../components/superadmin/noc/nocview";
import Importdata from "../components/superadmin/certificate/importdata";
import Generatecertificate from "../components/superadmin/certificate/generatecertificate";
import NationalCertificate from "../components/superadmin/certificate/nationallist";
import StateCertificate from "../components/superadmin/certificate/statelist";
import Approvedandrejected from "../components/superadmin/approved&rejected/approved&rejectedcount";
import Addresults from "../components/superadmin/results/addresults";
import ResultHistory from "../components/superadmin/results/resultlist";
import Athletestatus from "../components/superadmin/athletestatus/athletestatus";
import ViewMeet from "../components/superadmin/meet/viewmeet";
import AllEntries from "../components/superadmin/meet/allentries";
import NationalEntries from "../components/superadmin/meet/federationentries";
import EditAgeCategory from "../components/superadmin/meet/editageccategory";
import EditEventAgeCategory from "../components/superadmin/meet/editagecategoryevents";
import Overage from "../components/superadmin/overage/overage";
import OverageHistory from "../components/superadmin/overage/overagelist";
import MeetResultList from "../components/superadmin/results/meetwiseresult";
import AthleteResult from "../components/dashboard/athlete/result";
import Certificatelist from "../components/dashboard/athlete/certificatelist";
import Reapplyidcard from "../components/dashboard/athlete/reapplyidcard/reapplyidcard";
import ReapplySuccess from "../components/dashboard/athlete/reapplyidcard/reapplysuccess";
import Reapplyfailure from "../components/dashboard/athlete/reapplyidcard/reapplyfailure";
import Deliveredidcard from "../components/superadmin/reapplyidcard/deliveredidcard";
import Printedidcard from "../components/superadmin/reapplyidcard/printedidcard";
import Pendingidcard from "../components/superadmin/reapplyidcard/pendingidcard";
import AthletesList from "../components/dashboard/state/dashboardlist/athletelist";
import ViewAthlete from "../components/dashboard/state/dashboardlist/viewathlete";
import ReportedList from "../components/dashboard/state/dashboardlist/reportedlist";
import UpdateMissing from "../components/superadmin/athletes/updatemissing";
import { Certdashboard } from "../components/superadmin/certificate/dashboard";
import Logfile from "../components/superadmin/certificate/logfile";
import AdditionalAthleteEntryList from "../components/dashboard/state/athleteentrylistaddition";
import AdditionalSendEntries from "../components/dashboard/state/sententriesadditional";
import AdditionalAddAthletes from "../components/dashboard/state/addathletesadditional";
import { PaymentList } from "../components/payment";
import ViewProfilePayment from "../components/paymentviewathlete";
import InhandIdcard from "../components/superadmin/idcard/inhandlist";
import InhandDelivered from "../components/superadmin/idcard/deliveredlist";
import Completedmeet from "../components/dashboard/state/completedmeet";
import NidjamAthleteEntryList from "../components/dashboard/district/nidjam/athleteentrylits";
import NidjamOfficialEntryList from "../components/dashboard/district/nidjam/officialentrylist";
import NidjamTravelEntryList from "../components/dashboard/district/nidjam/travelentrylist";
import NidjamAddCommittee from "../components/dashboard/district/nidjam/selection";
import NidjamSummary from "../components/dashboard/district/nidjam/summary";
import NidjamSendEntries from "../components/dashboard/district/nidjam/sendentries";
import NidjamConfirmation from "../components/dashboard/district/nidjam/confirmation";
import NidjamAddTraveldetails from "../components/dashboard/district/nidjam/addtravel";
import NidjamAddDepaturedetails from "../components/dashboard/district/nidjam/adddeparture";
import NidjamAddOfficials from "../components/dashboard/district/nidjam/addofficials";
import NidjamAddAthletes from "../components/dashboard/district/nidjam/addathletes";
import NidjamNationalEntries from "../components/superadmin/meet/nidjamnational";
import NidjamAddAccountdetails from "../components/dashboard/district/nidjam/accountdetails";
import { NidjamSentDistrict } from "../components/superadmin/meet/nidjamsentdistrict";
import AllCoach from "../components/superadmin/coach/allcoach";
import ViewCoach from "../components/superadmin/coach/viewcoach";
import CoachDashboard from "../components/dashboard/coach/dashboard";
import EditCoach from "../components/superadmin/coach/editcoach";
import ManagerForm from "../components/dashboard/state/managerform";
import Managerfailure from "../components/dashboard/state/managerfailure";
import { ManagerCourseList } from "../components/superadmin/meet/managercourselist";
import { ManagerList } from "../components/dashboard/state/managerlist";
import AdditionalMeetaddathlete from "../components/dashboard/athlete/additionalmeetadd";
import AdditionalMeetviewathlete from "../components/dashboard/athlete/additionalmeetview";
export const dashboardroutes = [
  {
    id: 1,
    path: "/superadmin/dashboard",
    exact: false,
    element: <Dashboard />,
  },
  {
    id: 2,
    path: "/athlete/dashboard",
    exact: false,
    element: <AthleteDashboard />,
  },
  {
    id: 3,
    path: "/athlete/myprofile",
    exact: false,
    element: <Profile />,
  },
  {
    id: 4,
    path: "/athlete/accountdetail",
    exact: false,
    element: <Accountdetail />,
  },
  {
    id: 5,
    path: "/athlete/addaccount",
    exact: false,
    element: <Accountinfo />,
  },
  {
    id: 6,
    path: "/athlete/applynoc",
    exact: false,
    element: <Applynoc />,
  },
  {
    id: 7,
    path: "/athlete/idcard",
    exact: false,
    element: <Athleteidcard />,
  },
  {
    id: 8,
    path: "/athlete/changepassword",
    exact: false,
    element: <Accountsetting />,
  },
  {
    id: 9,
    path: "/state/dashboard",
    exact: false,
    element: <Statedashboard />,
  },
  {
    id: 10,
    path: "/state/upcoming",
    exact: false,
    element: <Upcoming />,
  },
  {
    id: 11,
    path: "/state/addathlete/:id",
    exact: false,
    element: <AddAthletes />,
  },
  {
    id: 12,
    path: "/state/athleteentrylist/:id",
    exact: false,
    element: <AthleteEntryList />,
  },
  {
    id: 13,
    path: "/state/addofficial/:id",
    exact: false,
    element: <AddOfficials />,
  },
  {
    id: 14,
    path: "/state/addarrival/:id",
    exact: false,
    element: <AddTraveldetails />,
  },

  {
    id: 15,
    path: "/state/changepassword",
    exact: false,
    element: <Accountsetting />,
  },
  {
    id: 16,
    path: "/state/myprofile",
    exact: false,
    element: <StateProfile />,
  },
  {
    id: 17,
    path: "/official/dashboard",
    exact: false,
    element: <Dashboard />,
  },
  {
    id: 18,
    path: "/official/changepassword",
    exact: false,
    element: <Accountsetting />,
  },
  {
    id: 19,
    path: "/official/myprofile",
    exact: false,
    element: <OfficialProfile />,
  },
  {
    id: 20,
    path: "/district/dashboard",
    exact: false,
    element: <DistrictDashboard />,
  },
  {
    id: 21,
    path: "/district/myprofile",
    exact: false,
    element: <DistrictProfile />,
  },
  {
    id: 22,
    path: "/district/changepassword",
    exact: false,
    element: <Accountsetting />,
  },
  {
    id: 23,
    path: "/club/dashboard",
    exact: false,
    element: <Dashboard />,
  },
  {
    id: 24,
    path: "/club/myprofile",
    exact: false,
    element: <ClubProfile />,
  },
  {
    id: 25,
    path: "/club/changepassword",
    exact: false,
    element: <Accountsetting />,
  },
  {
    id: 26,
    path: "/superadmin/pendingathletes",
    exact: false,
    element: <Pendingathlete />,
  },

  {
    id: 27,
    path: "/superadmin/approvedathletes",
    exact: false,
    element: <Approvedathlete />,
  },
  {
    id: 28,
    path: "/superadmin/rejectedathletes",
    exact: false,
    element: <Rejectedathlete />,
  },
  {
    id: 29,
    path: "/superadmin/blockedathletes",
    exact: false,
    element: <Blockedathlete />,
  },
  {
    id: 30,
    path: "/superadmin/viewprofile/:id",
    exact: false,
    element: <ViewProfile />,
  },
  {
    id: 31,
    path: "/superadmin/editathlete/:id",
    exact: false,
    element: <EditAthlete />,
  },
  {
    id: 32,
    path: "/superadmin/holdathletes",
    exact: false,
    element: <Holdathlete />,
  },
  {
    id: 33,
    path: "/superadmin/allathletes",
    exact: false,
    element: <Allathlete />,
  },
  {
    id: 34,
    path: "/superadmin/pendingdoc",
    exact: false,
    element: <Pendingdoc />,
  },
  {
    id: 35,
    path: "/superadmin/deletedathlete",
    exact: false,
    element: <Deletedathlete />,
  },
  {
    id: 36,
    path: "/superadmin/docupdate",
    exact: false,
    element: <DocUpdate />,
  },
  {
    id: 37,
    path: "/superadmin/similar",
    exact: false,
    element: <SimilarProfile />,
  },
  {
    id: 38,
    path: "/superadmin/addathlete",
    exact: false,
    element: <Addathlete />,
  },
  {
    id: 39,
    path: "/superadmin/addmeet",
    exact: false,
    element: <Addmeet />,
  },
  {
    id: 40,
    path: "/superadmin/addtournament/:id",
    exact: false,
    element: <Addmeetmember />,
  },
  {
    id: 41,
    path: "/superadmin/listmeet",
    exact: false,
    element: <Listmeet />,
  },
  {
    id: 42,
    path: "/district/addathlete/:id",
    exact: false,
    element: <Districtathlete />,
  },
  {
    id: 43,
    path: "/district/entrylist/:id",
    exact: false,
    element: <Districtentry />,
  },
  {
    id: 44,
    path: "/district/viewsummary/:id",
    exact: false,
    element: <Districtsummary />,
  },
  {
    id: 45,
    path: "/district/confirmation/:id",
    exact: false,
    element: <Confirmation />,
  },
  {
    id: 46,
    path: "/district/payment/:id",
    exact: false,
    element: <Payment />,
  },
  {
    id: 47,
    path: "/athlete/meetadd/:id",
    exact: false,
    element: <Meetaddathlete />,
  },
  {
    id: 48,
    path: "/athlete/meetview/:id",
    exact: false,
    element: <Meetviewathlete />,
  },
  {
    id: 49,
    path: "/state/selectioncommittee/:id",
    exact: false,
    element: <AddCommittee />,
  },
  {
    id: 50,
    path: "/state/summary/:id",
    exact: false,
    element: <Summary />,
  },
  {
    id: 51,
    path: "/state/sendentries/:id",
    exact: false,
    element: <SendEntries />,
  },
  {
    id: 52,
    path: "/state/officialentrylist/:id",
    exact: false,
    element: <OfficialEntryList />,
  },
  {
    id: 53,
    path: "/state/travelentrylist/:id",
    exact: false,
    element: <TravelEntryList />,
  },
  {
    id: 54,
    path: "/state/adddeparture/:id",
    exact: false,
    element: <AddDepaturedetails />,
  },
  {
    id: 55,
    path: "/state/viewarrival/:id/:aid",
    exact: false,
    element: <AddTraveldetails />,
  },
  {
    id: 56,
    path: "/state/viewdeparture/:id/:aid",
    exact: false,
    element: <AddDepaturedetails />,
  },
  {
    id: 57,
    path: "/state/confirmation/:id",
    exact: false,
    element: <StateConfirmation />,
  },

  {
    id: 58,
    path: "/master/agecategorylist",
    exact: false,
    element: <Agecategorylist />,
  },
  {
    id: 59,
    path: "/master/rolelist",
    exact: false,
    element: <Roles />,
  },
  {
    id: 60,
    path: "/master/systemconfiglist",
    exact: false,
    element: <Configuration />,
  },
  {
    id: 61,
    path: "/master/meetcategorylist",
    exact: false,
    element: <Meetcategory />,
  },
  {
    id: 62,
    path: "/master/entrytypelist",
    exact: false,
    element: <Entrycategory />,
  },
  {
    id: 63,
    path: "/master/statelist",
    exact: false,
    element: <States />,
  },
  {
    id: 64,
    path: "/master/districtlist",
    exact: false,
    element: <Districts />,
  },
  {
    id: 65,
    path: "/master/menulist",
    exact: false,
    element: <Menumaster />,
  },
  {
    id: 66,
    path: "/athlete/confirmation/:id",
    exact: false,
    element: <AthleteConfirmation />,
  },
  {
    id: 67,
    path: "/superadmin/approvedrejectlist",
    exact: false,
    element: <Approvedandrejected />,
  },
  {
    id: 68,
    path: "/superadmin/addresult",
    exact: false,
    element: <Addresults />,
  },
  {
    id: 69,
    path: "/superadmin/resulthistory",
    exact: false,
    element: <ResultHistory />,
  },
  {
    id: 70,
    path: "/superadmin/addoverage",
    exact: false,
    element: <Overage />,
  },
  {
    id: 71,
    path: "/superadmin/importdata",
    exact: false,
    element: <Importdata />,
  },
  {
    id: 72,
    path: "/superadmin/generatecertificate",
    exact: false,
    element: <Generatecertificate />,
  },
  {
    id: 73,
    path: "/superadmin/activieslog",
    exact: false,
    element: <Dashboard />,
  },
  {
    id: 74,
    path: "/superadmin/athletestatus",
    exact: false,
    element: <Athletestatus />,
  },
  {
    id: 75,
    path: "/superadmin/nocathletelist",
    exact: false,
    element: <NOCathletelist />,
  },
  {
    id: 76,
    path: "/superadmin/nocapproved",
    exact: false,
    element: <NOCapprovedlist />,
  },
  {
    id: 77,
    path: "/superadmin/nocrejected",
    exact: false,
    element: <NOCrejectedlist />,
  },
  {
    id: 78,
    path: "/athlete/noc/success",
    exact: false,
    element: <NOCSuccess />,
  },
  {
    id: 79,
    path: "/athlete/noc/failure",
    exact: false,
    element: <NOCFailure />,
  },
  {
    id: 80,
    path: "/superadmin/nocview/:nid/:id",
    exact: false,
    element: <ViewNOC />,
  },
  {
    id: 81,
    path: "/superadmin/nationalcertificate/:id/:type",
    exact: false,
    element: <NationalCertificate />,
  },
  {
    id: 82,
    path: "/superadmin/statecertificate/:id/:type",
    exact: false,
    element: <StateCertificate />,
  },
  {
    id: 83,
    path: "/superadmin/viewmeet/:id",
    exact: false,
    element: <ViewMeet />,
  },
  {
    id: 84,
    path: "/superadmin/nationalallentries/:id",
    exact: false,
    element: <AllEntries />,
  },
  {
    id: 85,
    path: "/superadmin/stateallentries/:id",
    exact: false,
    element: <AllEntries />,
  },
  {
    id: 86,
    path: "/superadmin/nationalsententries/:id",
    exact: false,
    element: <AllEntries />,
  },
  {
    id: 87,
    path: "/superadmin/statesententries/:id",
    exact: false,
    element: <AllEntries />,
  },
  {
    id: 88,
    path: "/superadmin/nationalpendingentries/:id",
    exact: false,
    element: <AllEntries />,
  },
  {
    id: 89,
    path: "/superadmin/statependingentries/:id",
    exact: false,
    element: <AllEntries />,
  },
  {
    id: 90,
    path: "/superadmin/nationalathleteentries/:id",
    exact: false,
    element: <NationalEntries />,
  },
  {
    id: 91,
    path: "/superadmin/nationalofficialentries/:id",
    exact: false,
    element: <NationalEntries />,
  },
  {
    id: 92,
    path: "/superadmin/nationalarrivalentries/:id",
    exact: false,
    element: <NationalEntries />,
  },
  {
    id: 93,
    path: "/superadmin/nationaldepartureentries/:id",
    exact: false,
    element: <NationalEntries />,
  },
  {
    id: 94,
    path: "/superadmin/nationalmosentries/:id",
    exact: false,
    element: <NationalEntries />,
  },
  {
    id: 95,
    path: "/superadmin/nationalperformancceentries/:id",
    exact: false,
    element: <NationalEntries />,
  },
  {
    id: 96,
    path: "/superadmin/nationalsentfederation/:id",
    exact: false,
    element: <NationalEntries />,
  },
  {
    id: 97,
    path: "/superadmin/meeteditbasicinformation/:id",
    exact: false,
    element: <Addmeet />,
  },
  {
    id: 98,
    path: "/superadmin/editagecategory/:id",
    exact: false,
    element: <EditAgeCategory />,
  },
  {
    id: 99,
    path: "/superadmin/editagecategoryevents/:id/:age",
    exact: false,
    element: <EditEventAgeCategory />,
  },
  {
    id: 100,
    path: "/superadmin/overagehistory",
    exact: false,
    element: <OverageHistory />,
  },
  {
    id: 101,
    path: "/superadmin/meetwiseresult/:id",
    exact: false,
    element: <MeetResultList />,
  },
  {
    id: 102,
    path: "/athlete/results",
    exact: false,
    element: <AthleteResult />,
  },
  {
    id: 103,
    path: "/athlete/certificatelist",
    exact: false,
    element: <Certificatelist />,
  },
  {
    id: 104,
    path: "/athlete/idcardreapply",
    exact: false,
    element: <Reapplyidcard />,
  },
  {
    id: 105,
    path: "/athlete/idcardsuccess",
    exact: false,
    element: <ReapplySuccess />,
  },
  {
    id: 106,
    path: "/athlete/idcardfailure",
    exact: false,
    element: <Reapplyfailure />,
  },
  {
    id: 107,
    path: "/superadmin/pendingidcard",
    exact: false,
    element: <Pendingidcard />,
  },
  {
    id: 108,
    path: "/superadmin/printedidcard",
    exact: false,
    element: <Printedidcard />,
  },
  {
    id: 109,
    path: "/superadmin/deliveryidcard",
    exact: false,
    element: <Deliveredidcard />,
  },
  {
    id: 110,
    path: "/state/athletelist",
    exact: false,
    element: <AthletesList />,
  },
  {
    id: 111,
    path: "/state/maleathletelist",
    exact: false,
    element: <AthletesList />,
  },
  {
    id: 112,
    path: "/state/femaleathletelist",
    exact: false,
    element: <AthletesList />,
  },
  {
    id: 113,
    path: "/state/nocathletelist",
    exact: false,
    element: <AthletesList />,
  },
  {
    id: 114,
    path: "/state/overageathletelist",
    exact: false,
    element: <AthletesList />,
  },
  {
    id: 115,
    path: "/state/athleteprofile/:id",
    exact: false,
    element: <ViewAthlete />,
  },
  {
    id: 116,
    path: "/state/reportedlist",
    exact: false,
    element: <ReportedList />,
  },
  {
    id: 117,
    path: "/superadmin/missingpayment",
    exact: false,
    element: <UpdateMissing />,
  },
  {
    id: 118,
    path: "/superadmin/athleteidcardview/:id",
    exact: false,
    element: <ViewProfile />,
  },
  {
    id: 119,
    path: "/superadmin/certificatedashboard",
    exact: false,
    element: <Certdashboard />,
  },
  {
    id: 120,
    path: "/superadmin/certificatehistory/:id",
    exact: false,
    element: <Logfile />,
  },
  {
    id: 121,
    path: "/state/additionalathleteentrylist/:id",
    exact: false,
    element: <AdditionalAthleteEntryList />,
  },
  {
    id: 122,
    path: "/state/additionaladdathlete/:id",
    exact: false,
    element: <AdditionalAddAthletes />,
  },
  {
    id: 123,
    path: "/state/additionalsendentries/:id",
    exact: false,
    element: <AdditionalSendEntries />,
  },
  {
    id: 124,
    path: "/superadmin/paymentlist",
    exact: false,
    element: <PaymentList />,
  },
  {
    id: 125,
    path: "/superadmin/viewathletedetail/:id",
    exact: false,
    element: <ViewProfilePayment />,
  },
  {
    id: 126,
    path: "/superadmin/inhandidcard",
    exact: false,
    element: <InhandIdcard />,
  },
  {
    id: 127,
    path: "/superadmin/deliveredidcard",
    exact: false,
    element: <InhandDelivered />,
  },
  {
    id: 128,
    path: "/state/completed",
    exact: false,
    element: <Completedmeet />,
  },
  {
    id: 129,
    path: "/district/athleteentrylist/:id",
    exact: false,
    element: <NidjamAthleteEntryList />,
  },
  {
    id: 130,
    path: "/district/officialentrylist/:id",
    exact: false,
    element: <NidjamOfficialEntryList />,
  },
  {
    id: 131,
    path: "/district/travelentrylist/:id",
    exact: false,
    element: <NidjamTravelEntryList />,
  },  
  {
    id: 132,
    path: "/district/selectioncommittee/:id",
    exact: false,
    element: <NidjamAddCommittee />,
  },
  {
    id: 133,
    path: "/district/meetsummary/:id",
    exact: false,
    element: <NidjamSummary />,
  },
  {
    id: 134,
    path: "/district/sendentries/:id",
    exact: false,
    element: <NidjamSendEntries />,
  },
  {
    id: 135,
    path: "/district/meetconfirmation/:id",
    exact: false,
    element: <NidjamConfirmation />,
  },
  {
    id: 136,
    path: "/district/addarrival/:id",
    exact: false,
    element: <NidjamAddTraveldetails />,
  },
  {
    id: 137,
    path: "/district/adddeparture/:id",
    exact: false,
    element: <NidjamAddDepaturedetails />,
  },
  {
    id: 138,
    path: "/district/viewarrival/:id/:aid",
    exact: false,
    element: <NidjamAddTraveldetails />,
  },
  {
    id: 139,
    path: "/district/viewdeparture/:id/:aid",
    exact: false,
    element: <NidjamAddDepaturedetails />,
  },
  {
    id: 140,
    path: "/district/addofficial/:id",
    exact: false,
    element: <NidjamAddOfficials />,
  },
  {
    id: 141,
    path: "/district/meetaddathlete/:id",
    exact: false,
    element: <NidjamAddAthletes />,
  },
  {
    id: 142,
    path: "/district/accountdetails/:id",
    exact: false,
    element: <NidjamAddAccountdetails />,
  },
  {
    id: 143,
    path: "/superadmin/nidjamathleteentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 144,
    path: "/superadmin/nidjamofficialentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 145,
    path: "/superadmin/nidjamarrivalentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 146,
    path: "/superadmin/nidjamdepartureentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 147,
    path: "/superadmin/nidjammosentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 148,
    path: "/superadmin/nidjamperformancceentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 149,
    path: "/superadmin/nidjampresscuttingentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 150,
    path: "/superadmin/nidjamphotoentries/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 151,
    path: "/superadmin/nidjamaccountdetails/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 152,
    path: "/superadmin/nidjamsentfederation/:id",
    exact: false,
    element: <NidjamNationalEntries />,
  },
  {
    id: 153,
    path: "/superadmin/nidjamsentdistrictfederation/:id/:dist",
    exact: false,
    element: <NidjamSentDistrict />,
  },
  {
    id: 154,
    path: "/superadmin/allcoaches",
    exact: false,
    element: <AllCoach />,
  },
  {
    id: 155,
    path: "/superadmin/pendingcoaches",
    exact: false,
    element: <AllCoach />,
  },
  {
    id: 156,
    path: "/superadmin/approvedcoaches",
    exact: false,
    element: <AllCoach />,
  },
  {
    id: 157,
    path: "/superadmin/blockedcoaches",
    exact: false,
    element: <AllCoach />,
  },
  {
    id: 158,
    path: "/superadmin/rejectedcoaches",
    exact: false,
    element: <AllCoach />,
  },
  {
    id: 159,
    path: "/superadmin/viewcoaches/:id",
    exact: false,
    element: <ViewCoach />,
  },
  {
    id: 160,
    path: "/superadmin/editcoaches/:id",
    exact: false,
    element: <EditCoach />,
  },
  {
    id: 161,
    path: "/coach/dashboard",
    exact: false,
    element: <CoachDashboard />,
  },
  {
    id: 162,
    path: "/superadmin/coachdocupdate",
    exact: false,
    element: <DocUpdate />,
  },
  {
    id: 163,
    path: "/district/additionalentrylist/:id",
    exact: false,
    element: <Districtentry />,
  },
  {
    id: 164,
    path: "/district/additionaladdathlete/:id",
    exact: false,
    element: <Districtathlete />,
  },
  {
    id: 165,
    path: "/district/additionalviewsummary/:id",
    exact: false,
    element: <Districtsummary />,
  },
  {
    id: 166,
    path: "/district/additionalconfirmation/:id",
    exact: false,
    element: <Confirmation />,
  },
  {
    id: 167,
    path: "/district/additionalpayment/:id",
    exact: false,
    element: <Payment />,
  },
  {
    id: 168,
    path: "/state/managerform",
    exact: false,
    element: <ManagerForm />,
  },
  {
    id: 169,
    path: "/state/managerpayment",
    exact: false,
    element: <Managerfailure />,
  },
  {
    id: 170,
    path: "/superadmin/managercourselist",
    exact: false,
    element: <ManagerCourseList />,
  },
  {
    id: 171,
    path: "/state/managerlist",
    exact: false,
    element: <ManagerList/>,
  },
  {
    id: 172,
    path: "/state/editmanagerform/:id",
    exact: false,
    element: <ManagerForm />,
  },
  {
    id: 173,
    path: "/athlete/additionalmeetadd/:id",
    exact: false,
    element: <AdditionalMeetaddathlete />,
  },
  {
    id: 174,
    path: "/athlete/additionalmeetview/:id",
    exact: false,
    element: <AdditionalMeetviewathlete />,
  },
];
export default {
  dashboardroutes,
};
