import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { athleteview } from "../../../services/athlete";
import {
  additionalmeettourdetails,
  meetdetails,
  meettourdetails,
  natindividualconfirmation,
  stateindividualconfirmation,
} from "../../../services/meetservice";
import imageUrl from "../../../ImageUrl";
import { distlist } from "../../../services/registerservice";
import fileString from "../../../assets/language/filepath.json";

const AthleteConfirmation = () => {
  const [cat, setCategory] = useState("");
  const [catname, setCategoryName] = useState("");
  const [mtype, setMType] = useState("");
  const [multi, setMulti] = useState("N");

  const [tourname, setTourname] = useState("");
  const [tourloc, setTourloc] = useState("");
  const [tourdate, setTourdate] = useState("");

  const [event1, setEvent1] = useState("");
  const [event1per, setEvent1Per] = useState("");
  const [event1proof, setEvent1Proof] = useState("");

  const [event2, setEvent2] = useState("");
  const [event2per, setEvent2Per] = useState("");
  const [event2proof, setEvent2Proof] = useState("");

  const [district, setDistrict] = useState("");
  const [aentry, setAEntry] = useState("");

  const [dob, setDOB] = useState("");
  const [dlist, setDistlist] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    athleteview(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setDOB(response.data.details.date_of_birth);
      
        meettourdetails(id, localStorage.getItem("Userid")).then((res) => {
          if (res.status === true) {
            setAEntry(res.data.tournament.allow_additional_entry)
            setTourname(res.data.tournament.meet_name);
            setTourloc(res.data.tournament.location);
            setTourdate(
              moment(res.data.tournament.start_date).format("DD/MM/YYYY") +
              " - " +
              moment(res.data.tournament.end_date).format("DD/MM/YYYY")
            );
            setMType(res.data.tournament.type);
            setMulti(res.data.tournament.multiple_events_allowed_flag);
            setCategoryName(res.data.tournament_age_category);

            if (res.data.tournament.type === "National Meet") {
              if (res.data.national_ind_entry !== null) {
                var evt = res.data.national_ind_entry.eventslist;

                var ch = evt.split(",");
                setEvent1(ch[0]);
                setEvent2(ch[1]);

                setEvent1Per(res.data.national_ind_entry.performance);
                setEvent2Per(res.data.national_ind_entry.performance_event_1);
                setEvent1Proof(res.data.national_ind_entry.performance_proof);
                setEvent2Proof(
                  res.data.national_ind_entry.performance_proof_event_1
                );
              }else{
                navigate("/athlete/dashboard")
              }
            } else {
              if (res.data.state_ind_entry_payment !== null) {
                if (res.data.state_ind_entry_payment.sent === 0) {
                  navigate("/athlete/dashboard")
                }
              }else{
                navigate("/athlete/dashboard")
              }

              if (res.data.state_ind_entry.length !== 0) {
                var evt = "";

                for (let k = 0; k < res.data.state_ind_entry.length; k++) {
                  if (
                    res.data.state_ind_entry[k].tournament_meet_id ===
                    parseInt(id) &&
                    res.data.state_ind_entry[k].user_id + "" ===
                    localStorage.getItem("Userid")
                  ) {
                    if (evt === "") {
                      evt = res.data.state_ind_entry[k].eventslist;
                    } else {
                      evt = evt + "," + res.data.state_ind_entry[k].eventslist;
                    }

                    setDistrict(res.data.state_ind_entry[k].club_dist_id);
                  }
                }

                var ch = evt.split(",");
                setEvent1(ch[0]);
                setEvent2(ch[1]);
              }
            }
          }
        });
        distlist(response.data.details.permenant_state).then((datas) => {
          if (datas.status === true) {
            setDistlist(datas.data);
          }
        });
      }
    });
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/placeholder.png");
    ev.target.onerror = null;
  }

  function downloadpdf() {
    if (localStorage.getItem("Userid") !== null) {
      if (mtype === "National Meet") {
        natindividualconfirmation(id, localStorage.getItem("Userid")).then(
          (response) => {
            if (response.status === true) {
              window.open(response.data, "_blank");
            }
          }
        );
      } else {
        stateindividualconfirmation(id, localStorage.getItem("Userid")).then(
          (response) => {
            if (response.status === true) {
              window.open(response.data, "_blank");
            }
          }
        );
      }
    }
  }
  return (
    <Fragment>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Confirmation</h2>
        </div>
      </div>

      <Card className=" custom-card overflow-hidden">
        <Card.Header>
          <Row className="row-sm">
            <Col
              sm={12}
              lg={9}
              xl={9}
              md={9}
              className="align-self-center border-end"
            >
              <h4 className="mb-0">{tourname}</h4>
            </Col>
            <Col sm={12} lg={3} xl={3} md={9}>
              <div className="eventdet ps-2">
                <h6>
                  <i className="fa fa-map-marker me-2 text-danger"></i>
                  {tourloc}
                </h6>
                <h6>
                  <i className="fa fa-calendar me-2 text-danger"></i>
                  {tourdate}
                </h6>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row className="mx-0">
            <Col md={12} className="px-0">
              <Card className="pt-0  custom-card pt-2 bg-background2 card pb-2 border-0 overflow-hidden text-center">
                <div className="header-text mb-0">
                  <Container fluid className="p-3 text-center">
                    <div className="text-center text-white background-text ">
                      <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                        Thank you for submitting all the entries !
                      </h1>
                      <p className="tx-20 mb-3 text-success">
                        All of the Entries were successfully submitted by you.{" "}
                        <br></br>Download the entry registration{" "}
                        <b>confirmation PDF</b> by clicking the button below..
                      </p>
                    </div>
                    <Row>
                      <Col xl={12} lg={12} md={12} className="d-block mx-auto">
                        <div className="text-center background-text">
                          <a
                            onClick={downloadpdf}
                            className="btn btn-danger ps-6 pe-6 pt-2 pb-2 mx-auto mt-1 mr-2"
                          >
                            <i className="fe fe-download-cloud me-2"></i>{" "}
                            Download Confirmation PDF
                          </a>

                          {mtype === "National Meet" && aentry === "Y" && event2 !== null &&(
                            <a
                            onClick={()=>navigate("/athlete/additionalmeetadd/"+id)}
                            className="btn btn-success ps-6 pe-6 pt-2 pb-2 mx-2 mt-1"
                          >
                            <i className="fe fe-plus me-2"></i>{" "}
                            Add Additional Entry
                          </a>
                          )

                          }
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Card>
            </Col>
          </Row>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                {mtype === "National Meet" && (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="profile-update individualentry">
                          <div className="row">
                            <div className="col-12 text-center pb-3 mb-3 border-bottom">
                              <h6>
                                Category <span className="text-danger">*</span>
                              </h6>
                              <button className="tabbtn active my-2 me-2">
                                {" "}
                                <h5 className="mb-0 tx-14">{catname}</h5>
                              </button>
                            </div>

                            <div className="col-md-6">
                              <Card className="custom-card border mb-0">
                                <Card.Header className="bg-light py-3 mb-4">
                                  <h6 className="tx-22 text-dark mb-0">
                                    Event One{" "}
                                  </h6>
                                </Card.Header>
                                <Card.Body>
                                  <div className="row mx-0 row-sm">
                                    <div className="col-md-12">
                                      <div className="profile-update">
                                        <div className="form-group">
                                          <h6> Event 1 </h6>
                                          <span>{event1}</span>
                                        </div>
                                        <div className="form-group">
                                          <h6> Event 1 Performance </h6>
                                          <span>{event1per}</span>
                                        </div>
                                        {event1proof !== null && (
                                          <div className="form-group">
                                            <h6>
                                              {" "}
                                              Event 1 Performance Proof{" "}
                                              <span className="desc text-danger tx-14">
                                                {" "}
                                                (Accepted Files are
                                                .jpg,.jpeg,.pdf)
                                              </span>
                                            </h6>

                                            <div className="imagecontainer mx-auto mt-3">
                                              {event1proof.substr(
                                                event1proof.length - 4
                                              ) !== ".pdf" && (
                                                  <img
                                                    src={
                                                      imageUrl +
                                                      fileString.tournamentproof +
                                                      event1proof
                                                    }
                                                    width="100%"
                                                    height="220"
                                                    onError={(e) =>
                                                      errorhandle(e)
                                                    }
                                                  />
                                                )}

                                              {event1proof.substr(
                                                event1proof.length - 4
                                              ) === ".pdf" && (
                                                  <a
                                                    href={
                                                      imageUrl +
                                                      fileString.tournamentproof +
                                                      event1proof
                                                    }
                                                    download
                                                  >
                                                    <img
                                                      src={require("../../../assets/images/pdf.png")}
                                                      width="100%"
                                                      height="220"
                                                      onError={(e) =>
                                                        errorhandle(e)
                                                      }
                                                    />
                                                  </a>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>

                            {multi === "Y" && (
                              <div className="col-md-6">
                                <Card className="custom-card border mb-0">
                                  <Card.Header className="bg-light py-3 mb-4">
                                    <h6 className="tx-22 text-dark mb-0">
                                      Event Two{" "}
                                    </h6>
                                  </Card.Header>
                                  <Card.Body>
                                    <div className="row mx-0 row-sm">
                                      <div className="col-md-12">
                                        <div className="profile-update">
                                          <div className="form-group">
                                            <h6> Event 2 </h6>
                                            <span>{event2}</span>
                                          </div>
                                          <div className="form-group">
                                            <h6> Event 2 Performance </h6>
                                            <span>{event2per}</span>
                                          </div>
                                          {event2proof !== null && (
                                            <div className="form-group">
                                              <h6>
                                                {" "}
                                                Event 2 Performance Proof{" "}
                                                <span className="desc text-danger tx-14">
                                                  {" "}
                                                  (Accepted Files are
                                                  .jpg,.jpeg,.pdf)
                                                </span>
                                              </h6>

                                              <div className="imagecontainer mx-auto mt-3">
                                                {event2proof.substr(
                                                  event2proof.length - 4
                                                ) !== ".pdf" && (
                                                    <img
                                                      src={
                                                        imageUrl +
                                                        fileString.tournamentproof +
                                                        event2proof
                                                      }
                                                      width="100%"
                                                      height="220"
                                                      onError={(e) =>
                                                        errorhandle(e)
                                                      }
                                                    />
                                                  )}

                                                {event2proof.substr(
                                                  event2proof.length - 4
                                                ) === ".pdf" && (
                                                    <a
                                                      href={
                                                        imageUrl +
                                                        fileString.tournamentproof +
                                                        event2proof
                                                      }
                                                      download
                                                    >
                                                      <img
                                                        src={require("../../../assets/images/pdf.png")}
                                                        width="100%"
                                                        height="220"
                                                        onError={(e) =>
                                                          errorhandle(e)
                                                        }
                                                      />
                                                    </a>
                                                  )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {mtype !== "National Meet" && (
                  <div className="row">
                    <div className="col-12 text-center pb-3 mb-3 border-bottom">
                      <h6>
                        Category <span className="text-danger">*</span>
                      </h6>
                      <button className="tabbtn active my-2 me-2">
                        {" "}
                        <h5 className="mb-0 tx-14">{catname}</h5>
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div className="profile-update">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">District</b>
                          </div>
                          <div className="col-md-8">
                            <select
                              value={district}
                              onChange={(e) => {
                                setDistrict(e.target.value);
                              }}
                              disabled
                            >
                              <option value="" disabled>
                                Select Your District
                              </option>
                              {dlist.map((data, i) => {
                                return (
                                  <option value={data.districtuid} key={i}>
                                    {data.districtname}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="profile-update">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Event 1</b>
                          </div>
                          <div className="col-md-8">
                            <span>{event1}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {multi === "Y" && (
                      <div className="col-md-12">
                        <div className="profile-update">
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Event 2</b>
                            </div>
                            <div className="col-md-8">
                              <span>{event2}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <hr />
          </div>

          <div className="text-right">
            <Link className="btn btn-secondary mr-2" to={"/athlete/dashboard"}>
              Back
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default AthleteConfirmation;
